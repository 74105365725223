// dat.gui styles
.dg {
  opacity: .95;

  &.main {
    .close-button {
      background-color: rgba(0, 0, 0, 0.6) !important;
    }

    li {
      background-color: rgba(0, 0, 0, 0.3) !important;
      border-left: none !important;
    }
  }

  .title {
    margin-left: 0 !important;
  }

}

#gui {
  position: absolute;
  top: 0;
  left: 0;
  
  .gui-stats {
    height: 48px;

    canvas {
      opacity: .75;
    }
  }

  .gui-canvas {
    height: auto;
    white-space: nowrap;
    overflow: hidden;
    padding: 0;
    background-color: #000 !important;


    canvas {
      width: 160px;
      float: left;
      display: block;
      transform: translateZ(0) scaleX(-1);
      outline: 1px solid rgba(255, 255, 255, 0.1);
      &.tiny {
        width: 80px;
        height: 80px;
      }
    }

  }

  .closed {
    .gui-stats {
      display: none;
    }

    .gui-canvas {
      display: none;
    }
  }

}