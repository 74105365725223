body {
  background-color: #000;
  color: #eee;
  margin: 0;
}

.content {
  display: none;

  canvas, video {
    display: block;
    width: 100%;
    height: auto;
    margin: 0 auto;
  }
}

body.init .welcome-msg {
   display: block;
}

body.loading .loading-msg {
   display: block;
}

body.loaded {
  .content {
    display: flex;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    
    canvas, video {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }
}

body.fallback {
  .content {
    height: calc(100vh - 128px);

    button {
      position: absolute;
      background-color: rgba(0,0,0,.25);
      padding: 1em 2em;
      font-size: 20px;
      color: #fff;
      border: none;
      cursor: pointer;
      border-radius: 6px;

      &:focus {
        outline: none;
      }

      &:hover {
        background-color: rgba(0,0,0,.5);
      }
    }
  }
}

.debug {
  video, canvas { 
    float: left;
    transform: scale(-1, 1);
  }
}

// notes (welcome + loading)
.note {
  margin: 1rem;
  padding: .5rem .75rem;
  border: 1px solid rgba(100, 100, 100, 0.125);
  background-color: rgba(100, 100, 100, 0.125);
  display: none;
}

.deviceSwitcher {
  position: absolute;
  z-index: 3000;
  right: 24px;
  bottom: 24px;
  height: 30px;
  width: 30px;

  border: none;
  padding: 0;
  cursor: pointer;
  outline: inherit;

  background-color: transparent;
  background: url("/images/camera_icon_01.svg") no-repeat center center / auto 24px;
}
